<template>
  <div
    class="about-block grid dc--2 dg--xxlarge mc--1"
    :class="{
      [`mp--${mobilePadding}`]: Boolean(mobilePadding),
      [`dp--${desktopPadding}`]: Boolean(desktopPadding),
      [`mm--${mobileMargin}`]: Boolean(mobileMargin),
      [`dm--${desktopMargin}`]: Boolean(desktopMargin),
    }"
    :style="[backgroundColorCss, shopMissionStatementLineColorCss]"
  >
    <div class="desktop-left-column">
      <div class="about-block__image-wrapper mp--small dp--medium">
        <div
          class="
            about-block__image
            desktop-format--square
            mobile-format--square
          "
        >
          <ResponsiveImage
            v-if="mobileImageFilename || desktopImageFilename"
            :lazyload="true"
            :mobile-src="mobileImageFilename"
            :desktop-src="desktopImageFilename"
            :alt="mobileImageAlt"
            mobile-display-size="'100vw'"
            desktop-display-size="'34vw'"
          />
        </div>
      </div>
      <component
        :is="button.component"
        v-for="(button, i) in buttons"
        :key="`about-block__button__desktop${_uid}${i}`"
        class="about-block__button show-on-desktop"
        v-bind="button"
      />
    </div>

    <div class="desktop-right-column">
      <div class="about-block__text">
        <component
          :is="textBlock.component"
          v-for="(textBlock, i) in textBlockDesktop"
          :key="`about-block__text__desktop${_uid}${i}`"
          :class="{ 'show-on-desktop': !!textBlockMobile }"
          v-bind="textBlock"
        />
        <component
          :is="textBlock.component"
          v-for="(textBlock, i) in textBlockMobile"
          :key="`about-block__text__mobile${_uid}${i}`"
          :class="{ 'show-on-mobile': !!textBlockDesktop }"
          v-bind="textBlock"
        />
      </div>
      <div class="about-block__shopMissionStatements">
        <component
          :is="shopMissionStatement.component"
          v-for="(shopMissionStatement, i) in shopMissionStatements"
          :key="`about-block__shopMissionStatement${_uid}${i}`"
          v-bind="shopMissionStatement"
        />
      </div>
    </div>
    <component
      :is="button.component"
      v-for="(button, i) in buttons"
      :key="`about-block__button__mobile${_uid}${i}`"
      class="about-block__button show-on-mobile"
      v-bind="button"
    />
  </div>
</template>
<script>
export default {
  name: 'About',
  props: {
    textBlockDesktop: {
      type: Array,
      default: () => [],
    },
    textBlockMobile: {
      type: Array,
      default: () => [],
    },
    mobileImage: {
      type: Object,
      required: false,
      default: null,
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    shopMissionStatements: {
      type: Array,
      default: () => [],
    },
    bodyText: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'grey',
    },
    shopMissionStatementLineColor: {
      type: String,
      default: 'black',
    },
    mobileMargin: {
      type: String,
      default: '',
    },
    mobilePadding: {
      type: String,
      default: '',
    },
    desktopMargin: {
      type: String,
      default: '',
    },
    desktopPadding: {
      type: String,
      default: '',
    },
  },
  computed: {
    mobileImageFilename() {
      return this.mobileImage?.filename || ''
    },
    desktopImageFilename() {
      return this.desktopImage?.filename || ''
    },
    mobileImageAlt() {
      return this.mobileImage?.alt || ''
    },
    backgroundColorCss() {
      return { '--about-background-color': `var(--${this.backgroundColor}` }
    },
    shopMissionStatementLineColorCss() {
      return {
        '--shop-mission-statement--line-color': `var(--${this.shopMissionStatementLineColor}`,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.about-block {
  display: flex;
  flex-flow: column;

  .about-block__image-wrapper {
    padding-bottom: 0;
  }
  .about-block__image {
    border-radius: 100%;
    overflow: hidden;
    z-index: 3;
  }

  .about-block__text {
    position: relative;
    z-index: 2;

    &::v-deep {
      .headline {
        padding-left: spacing('medium');

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 5rem;
          display: block;
          width: 100%;
          height: 24rem;
          background-color: var(--about-background-color);
          z-index: -2;
        }
      }

      .text {
        padding-top: spacing('medium');
        padding-left: spacing('xxsmall');
        padding-right: spacing('xxsmall');

        line-height: 1.8em;
      }
    }
  }

  .about-block__shopMissionStatements {
    padding-top: spacing('medium');
    padding-bottom: spacing('medium');
    padding-left: spacing('xxsmall');
    padding-right: spacing('xxsmall');
  }

  .about-block__button {
    margin-left: spacing('xxsmall');
    margin-right: spacing('xxsmall');
  }
}

@media screen and (min-width: $tablet-landscape) {
  .about-block {
    .about-block__image-wrapper {
      padding-bottom: spacing('xxxlarge');
    }

    .about-block__image {
      max-width: 60rem;
      margin-left: auto;
      margin-right: auto;
    }

    .about-block__text {
      &::v-deep {
        .headline {
          padding-left: 0;
          padding-bottom: 7%;
          margin-left: -35%;

          &:after {
            display: none;
          }
        }

        .text {
          padding-bottom: spacing('large');
        }
      }
    }

    .desktop-left-column {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 60%;
        background-color: var(--about-background-color);
      }
    }

    .desktop-right-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 3;
    }

    .about-block__shopMissionStatements {
      padding-bottom: 0;
    }

    .about-block__button {
      margin-left: spacing('small');
      margin-right: spacing('small');
      margin-bottom: spacing('small');
    }
  }
}
</style>
